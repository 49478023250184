
.NavbarItems {
  background-color: #fff;
  box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.25);
  width: 100vw;
  height: 80px;
  display: flex;
  justify-content: space-between;
  padding: 0 60px;
  position: fixed;
  left: 50%;
  z-index: 9999;
  transform: translate(-50%);
  a {
    color: #2b2b2b;
    text-decoration: none;
  }
  a:hover {
    font-weight: bold;
    color: #2b2b2b;
  }
}

.navbar-logo {
  color: #222;
  font-size: 2rem;
  justify-self: start;
  cursor: pointer;
}

.fa-react {
  margin-left: 0.2rem;
  font-size: 1.6rem;
}

.nav-menu {
  display: grid;
  grid-template-columns: repeat(6, auto);
  grid-gap: 10px;
  list-style: none;
  align-items: center;
  text-align: center;
  justify-content: end;
}

.nav-links {
  color: #222;
  text-decoration: none;
  padding: 0.7rem 1rem;
  font-size: 1.2rem;
  font-weight: 600;
  white-space: nowrap;
}

.nav-links i {
  padding-right: 10px;
}

.nav-links:hover {
  background-color: #01959a;
  color: #fff;
  border-radius: 4px;
  transition: all 0.2s ease-in-out;
}

button {
  padding: 0.5rem 1rem;
  white-space: nowrap;
  border-radius: 0.3rem;
  font-size: 1rem;
  font-weight: 600;
  border: none;
  cursor: pointer;
  transition: 0.2s ease-in-out;
}

button:hover {
  background-color: #151516;
  color: #fff;
}

.fa-bars {
  color: #222;
}

.nav-links-mobile {
  display: none;
}

.menu-icons {
  display: none;
}

.nav-item {
  position: relative;
}

.dropdown {
  cursor: pointer;
}

.dropdown-menu {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #fff;
  box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.25);
  z-index: 1;
  padding: 10px 0;
  list-style: none;
}

.dropdown:hover .dropdown-menu {
  display: block;
}

.dropdown-link {
  display: block;
  padding: 20px 20px;
  color: #222;
  text-decoration: none;
  white-space: nowrap;
  font-weight: 600;
  font-size: 18px;
  text-align: left;
}

.dropdown-link:hover {
  background-color: #01959a;
  color: #fff;
  transition: all 0.2s ease-in-out;
}
@media screen and (max-width: 820px) {
  .NavbarItems {
    .nav-menu {
      background-color: #fff;
      border-radius: 13px;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      width: 100vw;
      height: auto;
      position: absolute;
      top: 0px;
      left: -110%;
      opacity: 1;
      transition: all 0.3s ease;
      align-items: stretch;
      padding: 60px 0 30px 0;
      margin: 0;
    }
  
    .nav-menu.active {
      left: 0;
      opacity: 1;
      transition: all 0.3s ease;
      z-index: -1;
    }
  
    .nav-links {
      display: block;
      width: 100%;
      font-size: 1.2rem;
      padding: 2rem 0;
      
    }
  
    .nav-links:hover {
      background: #01959a;
      transition: none;
    }
  
    .menu-icons {
      display: block;
      position: absolute;
      top: 25px;
      right: 30px;
      font-size: 1.5rem;
      cursor: pointer;
    }
  
    .nav-links-mobile {
      display: block;
      text-align: center;
      padding: 1.5rem;
      margin: auto;
      border-radius: 4px;
      width: 80%;
      background: #01959a;
      text-decoration: none;
      color: #fff;
      font-weight: 700;
      letter-spacing: 2px;
      font-size: 1.2rem;
    }
    .fa-times {
      color: #222;
    }
  
    button {
      display: none;
    }
    .dropdown-menu {
      position: static;
      box-shadow: none;
      background-color: transparent;
    }
  
    .dropdown-link {
      padding: 0;
      text-align: start;
    }
    .nav-links {
      padding: 0.8rem 0;
      text-align: start;
      width: 100%;
    }
  }
  
}

/* @media screen and (min-width: 481px) and (max-width: 820px) {
  .dropdown-menu {
    position: static;
    box-shadow: none;
    background-color: transparent;
  }

  .dropdown-link {
    padding: 0;
    text-align: center;
  }
  .nav-links {
    padding: 0.8rem 0;
    text-align: center;
    width: 100%;
  }
  .NavbarItems{
    width: 100vw;
  }
} */
@media screen and (min-width: 821px) and (max-width: 1200px) {
 
  .nav-menu {
    display: grid;
    grid-template-columns: repeat(6, auto);
    grid-gap: 10px; /* Adjust the gap to ensure all items fit */
    list-style: none;

  }
  
  .dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    background-color: #fff;
    box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.25);
    z-index: 1;
    padding: 5px 0;
    width: auto; /* Ensure the dropdown menu has auto width */

  }

  .dropdown-link {
    padding: 10px 4px; /* Adjust padding for better alignment */
    text-align: center;
  }

  .nav-links {
    padding: 0.5rem 0.1rem; /* Adjust padding for better spacing */
    text-align: center;
    width: auto; /* Ensure the links have auto width */
    margin:0.2rem
  }
  .NavbarItems {
    display: flex;
    justify-content: center;
    h1 {
      display: none;
    }
  }
}
