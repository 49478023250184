a {
  color: #2b2b2b;
  word-wrap: break-word;
}
a:hover {
  color: #dcac3b;
}
@media screen and (max-width: 480px) {
  h1 {
    font-size: 26px;
  }
}
@media screen and (min-width: 481px) and (max-width: 820px) {
  h1 {
    font-size: 30px;
  }
}
