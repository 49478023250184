.restaurant-container {
  color: rgb(63, 63, 63);
  width: 95%;
  padding: 40px;
  font-size: 25px;
  text-align: start;
  ul {
    padding-left: 60px;
  }
  .section {
    width: 100%;
    display: flex;
    justify-content: space-between;
    .image {
      width: 30%;
      img {
        width: 100%;
      }
    }
  }
}

@media screen and (max-width: 820px) {
  .restaurant-container {
    .section {
      width: 100%;
      display: flex;
      flex-direction: column;
      .image {
        width: 100%;
        img {
          width: 100%;
        }
      }
    }
  }
}
