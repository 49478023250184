.gallery-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 20px;
}

.thumbnails {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  overflow-x: auto;
  max-width: 100%;
}

.thumbnail {
  width: 25%;
  height: 20%;
  object-fit: cover;
  margin: 5px;
  cursor: pointer;
}

.thumbnail:hover {
  transform: scale(1.3);
  overflow: hidden;
}

.lightbox {
  position: fixed;
  top: 80px;
  left: 0;
  width: 100%;
  height: calc(100vh - 80px);
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.large-image {
  max-width: 80%;
  max-height: 80%;
  margin-bottom: 20px;
  position: relative;
}

.controls {
  position: absolute;
  top: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.icon-button {
  border: none;
  color: white;
  font-size: 24px;
  cursor: pointer;
  z-index: 99;
  background: rgba(0, 0, 0, 0.8);
  .icon-button:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
}

.close-button {
  position: absolute;
  top: 30px;
  right: 5%;
  transform: translateX(50%);
  z-index: 99;
}

.prev-button {
  position: absolute;
  top: 50%;
  left: 3%;
  transform: translateY(-50%);
  z-index: 99;
}

.next-button {
  position: absolute;
  top: 50%;
  right: 3%;
  transform: translateY(-50%);
}

.thumbnail-row {
  display: flex;
  overflow-x: auto;
  margin-top: 20px;
}

.thumbnail-row .thumbnail {
  width: 50px;
  height: 50px;
  margin: 5px;
}

.thumbnail-row .thumbnail.active {
  border: 2px solid white;
}
@media screen and (max-width: 480px) {
  .large-image {
    max-width: 90%;
    max-height: 80%;
    margin-bottom: 20px;
    position: relative;
  }
  .close-button {
    right: 11%;
  }
  .prev-button {
    position: absolute;
    top: 50%;
    left: 5%;
    transform: translateY(-50%);
    z-index: 99;
  }

  .next-button {
    position: absolute;
    top: 50%;
    right: 5%;
    transform: translateY(-50%);
  }
  .icon-button {
    background: rgba(0, 0, 0, 0.1);
    border: none;
    color: white;
    font-size: 24px;
    cursor: pointer;
    z-index: 99;
    .icon-button:hover {
      background-color: rgba(0, 0, 0, 0.1);
    }
  }
}
