.trip {
  margin: 4rem 6rem;
  color: #2a2a2a;
}

.trip h1 {
  font-size: 3rem;
}

.tripcard {
  margin-top: 3rem;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.t-card {
  width: 32%;
  text-align: start;
  box-shadow: 0 5px 25px 2px rgba(0, 0, 0, 0.11);
  border-radius: 7px;
  cursor: pointer;
  padding: 1rem 0.8rem;
  display: flex;
  flex-direction: column; /* Add this to make children stack vertically */
  justify-content: space-between; /* Add this to space items apart */
  height: 692px; /* Add this to ensure full height */
  box-sizing: border-box; /* Add this to include padding in height */
}
.t-image {
  height: 200px;
  overflow: hidden;
  border-radius: 7px;
}

.t-image img {
  width: 100%;
  height: 100%;
  border-radius: 7px;
  transition: 0.3s ease-in-out;
}

.t-card:hover img {
  transform: scale(1.3);
  overflow: hidden;
}

.t-card h4 {
  font-size: 1.3rem;
  padding: 0.9rem 0 0.2rem 0;
}

.trip-link {
  display: inline-block;
  margin-top: 10px;
  padding: 10px 15px;
  background-color: #007bff;
  color: #fff;
  text-decoration: none;
  border-radius: 4px;
  transition: background-color 0.3s ease;
}

.trip-link:hover {
  background-color: #0056b3;
}

/* @media screen and (max-width: 850px) {
  .trip {
    margin: 4rem 2rem;
  }
  .tripcard {
    margin-top: 3rem;
    display: flex;
    flex-direction: column;
  }
  .t-card {
    margin-bottom: 1.5rem;
    width: 100%;
  }
} */
@media screen and (max-width: 480px) {
  .trip {
    margin: 4rem 2rem;
    span {
      display: none;
    }
  }
  .tripcard {
    margin-top: 3rem;
    display: flex;
    flex-direction: column;
  }
  .t-card {
    margin-bottom: 1.5rem;
    width: 100%;
    height: 600px;
  }
}

@media screen and (min-width: 481px) and (max-width: 1280px) {
  .trip {
    margin: 4rem 2rem;
  }
  .tripcard {
    margin-top: 3rem;
    display: flex;
    flex-direction: row;
  }
  .t-card {
    margin-bottom: 1.5rem;
    width: 48%;
    height: 600px;
  }
}

@media screen and (min-width: 1281px) {
}
