.footer {
  background: #000;
  color: #fff;
  padding: 4rem 6rem;
  
}

.top {
  display: flex;
  align-items: center;
  text-align: start;
  justify-content: space-between;
  flex-wrap: wrap;
}
.top i {
  color: #fff;
  font-size: 2rem;
  margin-left: 1rem;
}

.top i:hover {
  color: #01959a;
}

.bottom {
  padding-top: 2rem;
  text-align: start;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.bottom div {
  display: flex;
  flex-direction: column;
}

.bottom h4 {
  font-size: 1.3rem;
  padding: 1rem 0 0.8rem 0;
  color: #fafafa;
}

.bottom a {
  text-decoration: none;
  color: #fafafa;
  padding-bottom: 0.4rem;
  font-size: 1.1rem;
}

a {
  text-align: start;
}
a:hover {
  font-weight: normal;
  color: white;
  font-weight: bold;
}

.footer-logo {
  color: white;
  text-decoration: none;
}

.contact-footer {
  .contact {
    display: inline-block;
    a:hover {
      font-weight: normal;
    }
  }
}

.contact .title,
.contact a {
  display: inline-block;
  vertical-align: middle;
  margin-right: 5px;
  height: 40px;
  font-size: 1.2rem;
}

@media screen and (max-width: 850px) {
  .footer {
    padding: 4rem 2rem;
  }

  .footer i {
    margin: 1rem 1rem 0 0;
  }
}
