.activity-container {
  color: rgb(63, 63, 63);
  width: 100%;
  padding: 40px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  .section {
    display: flex;
    flex-direction: row;
    gap:30px;
    .image {
      width: 50%;
      padding-top: 20px;
      img{
        width: 100%;
      }
    }
    .paragraph {
      padding-top: 30px;
      width: 50%;
      text-align: start;
      .title {
        font-weight: 700;
      }
      a{
        word-wrap: break-word;
      }
    }
  }
}

@media screen and (max-width: 820px) {
  .activity-container {
    .section {
      flex-direction: column;
      .image {
        width: 100%;
      }
      .paragraph {
        width: 100%;
        
      }
    }
  }
}
