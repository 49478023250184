/* Container for the form */
h1 {
  padding-top: 20px;
}

.contact-form {
  margin: 20px;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #f9f9f9;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  text-align: start;
}

.two-column {
  display: flex;
  flex-direction: row;
  gap: 20px; /* Adjust gap between columns */
}

.inputValue {
  display: flex;
  flex-direction: column;
  width: 50%;
  margin: 20px;
  font-weight: bold;
  input {
    border: 1px solid #dcdcdc;
    height: 36px;
    padding-left: 20px;
    padding-right: 20px;
    width: 100%;
  }
  .react-datepicker__tab-loop {
    .react-datepicker-popper {
      color: #2b2b2b;
      .react-datepicker {
        .react-datepicker__navigation--next {
          top: 25px;
          .react-datepicker__navigation-icon--next {
            top: 2px;
          }
        }
        .react-datepicker__navigation--previous {
          top: 25px;
          .react-datepicker__navigation-icon--previous {
            top: 2px;
          }
        }
        .react-datepicker__month-container {
          .react-datepicker__header {
            .react-datepicker__current-month {
              font-size: 20px;
            }
            .react-datepicker__day-names {
              font-weight: normal;
            }
          }
          .react-datepicker__month {
            font-weight: normal;
            .react-datepicker__week {
              .react-datepicker__day--today {
                color: #2b2b2b;
                border-radius: 50%;
                border: 2px solid green;
              }
              .react-datepicker__day--disabled {
                color: #2b2b2b;
              }
              .react-datepicker__day--keyboard-selected{
                background-color: white;
              }
            }
          }
        }
      }
    }
  }
}

.label-input {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;
}

.label-input label {
  font-weight: bold;
  width: 30%;
}

.label-input input {
  flex: 1; /* Expand input to fill remaining space */
  padding: 8px;
  margin-left: 10px; /* Add left margin for spacing */
  border: 1px solid #dcdcdc;
  border-radius: 4px;
  box-sizing: border-box;
}
.phoneInput {
  display: flex;
  flex-direction: row;
  margin: 20px;
}
.react-international-phone-input-container {
  width: 90%;
}
.react-international-phone-input {
  width: 100%;
}

.error-message {
  color: red;
  font-size: 12px;
  margin-top: 5px;
}

.phoneInput {
  display: flex;
  flex-direction: column;
  width: 50%;
}

.phoneInput label {
  font-weight: bold;
  width: 30%;
}

.children-age {
  display: flex;
  flex-direction: row;
  gap: 20px;
}

.children-age .inputValue {
  display: flex;
  flex-direction: column;
  width: 50%;
}

.children-age .inputValue label {
  font-weight: bold;
}

.children-age .inputValue select {
  width: 100%;
  padding: 8px;
  border: 1px solid #dcdcdc;
  border-radius: 4px;
  box-sizing: border-box;
}

.message {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin: 20px;
}

.message label {
  font-weight: bold;
}

.message textarea {
  width: 100%;
  height: 150px;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

.button {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.submit {
  background-color: #4caf50;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
}

.submit:hover {
  background-color: #45a049;
}

.submit:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}
.loading-image{
  width: 95vw;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.loading-image img {
  width: 100px;
  height: 100px;
}
.success-message {
  margin: 10px;
  background-color: #d4edda;
  color: #155724;
  padding: 10px;
  margin-top: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
.retry-message {
  margin: 10px;
  background-color: #f3afbe;
  color: red;
  padding: 10px;
  margin-top: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .contact-form {
    .two-column {
      width: 90%;
      flex-direction: column;
      gap: 0px;
      .inputValue {
        width: 100%;
      }
    }

    .phoneInput {
      width: 100%;
      input {
        width: 100%;
      }
    }

    .label-input input,
    .PhoneInput,
    select,
    textarea {
      width: calc(100% - 10px); /* Adjusted to compensate for margin-left */
    }

    .children-age .inputValue,
    .message {
      width: 95%;
    }
  }
}
